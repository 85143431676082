import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
// import globalStore from '@/services/global.state';
import theme from '@/style';
import {BasicObject} from '@/types';
import {downloadApk, stopBeforeDownload} from '@/utils';
import React from 'react';
import {View, Image} from 'react-native';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {inApp} from '@/utils/interface';

const onClickDownloadBtn = () => {
  if (stopBeforeDownload()) {
    return;
  }
  downloadApk();
};

const TextOpacity = 0.7;
const DownloadBanner = () => {
  const {calcActualSize} = useScreenSize();
  const [hide, setHide] = React.useState(false);
  if (inApp) {
    return <View />;
  }
  return hide ? (
    <View />
  ) : (
    <View
      style={[
        theme.fill.fillW,
        theme.padding.tbxxs,
        theme.padding.lrl,
        theme.flex.row,
        theme.position.rel,
        theme.flex.between,
        {
          background: 'linear-gradient(91deg, #81430A 0%, #A2751D 100%)',
          zIndex: 1,
        } as BasicObject,
      ]}>
      <View style={[theme.flex.row, theme.position.rel, theme.padding.tbxxs]}>
        <Image
          source={require('@assets/imgs/home/logo.png')}
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            height: 40,
            width: 40,
            borderRadius: theme.borderRadiusSize.m,
          }}
        />
        <View style={[theme.flex.col, theme.flex.around, theme.margin.lefts]}>
          <Text blod size="medium" color={theme.basicColor.white}>
            99 Lotto APP
          </Text>
          <Text
            color={theme.basicColor.white}
            style={{
              opacity: TextOpacity,
            }}>
            Give you a new experience
          </Text>
        </View>
      </View>
      <View
        style={[
          theme.flex.flex,
          theme.flex.row,
          theme.flex.centerByCol,
          {
            gap: calcActualSize(12),
          },
        ]}>
        <NativeTouchableOpacity onPress={onClickDownloadBtn}>
          <Image
            source={require('@assets/imgs/home/download.webp')}
            style={
              // eslint-disable-next-line react-native/no-inline-styles
              {
                width: 104,
                height: 36,
              }
            }
          />
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          onPress={() => setHide(true)}
          style={[theme.flex.center]}>
          <Image
            style={theme.icon.m}
            source={require('@components/assets/icons/close.webp')}
          />
        </NativeTouchableOpacity>
      </View>
    </View>
  );
};

export default DownloadBanner;
