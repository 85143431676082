import Wallet from '@/common-pages/wallet';
import Button from '@/components/basic/button';
import NavTitle from '@/components/basic/nav-title';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {BasicObject} from '@/types';
import {goTo} from '@/utils';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Image, View} from 'react-native';
import {Shadow} from 'react-native-shadow-2';
import {combineLatest} from 'rxjs';

const shadow = {
  startColor: 'rgba(0, 0, 0, 0.25)',
  distance: 4,
  offset: [0, 1],
  style: [theme.borderRadius.m, theme.overflow.hidden] as BasicObject[],
} as BasicObject;

if (globalStore.isWeb) {
  shadow.distance = 0;
  delete shadow.offset;
  shadow.style.push({
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
  });
}

const HomeHeader = () => {
  const {i18n} = useTranslation();
  const [hasLogin, setHasLogin] = useState(false);
  React.useEffect(() => {
    const tokenSub = combineLatest([globalStore.tokenSubject]).subscribe(
      ([t]) => {
        setHasLogin(!!t);
      },
    );
    return () => {
      tokenSub.unsubscribe();
    };
  }, []);
  return (
    <NavTitle showProgress containerStyle={[theme.background.primary]}>
      <View style={[theme.flex.row, theme.flex.centerByCol]}>
        <Image
          source={require('@assets/imgs/home/99logo.webp')}
          style={[
            theme.margin.rightxxs,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              width: 210,
              height: 32.5,
            },
          ]}
        />
      </View>
      <View style={[theme.flex.flex1]} />
      {hasLogin ? (
        <Wallet />
      ) : (
        <Shadow {...shadow}>
          <Button
            onPress={() => goTo('Login')}
            buttonStyle={theme.background.accent}
            radius={theme.borderRadiusSize.m}
            title={i18n.t('login.label.login')}
          />
        </Shadow>
      )}
    </NavTitle>
  );
};
export default HomeHeader;
