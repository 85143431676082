import i18n from '@/i18n';
import {goTo} from '@/utils';

export const notYetWarning: {type: 'warning' | 'success'; message: string} = {
  type: 'warning',
  message: i18n.t('warning.unopen'),
};

export function toLogin() {
  goTo('Login');
}

export const topBackground = require('@assets/icons/me/background.webp');
export const defaultHeaderImg = require('@components/assets/icons/default-header.webp');
export const copyIcon = require('@assets/icons/me/copy.webp');
export const refreshIcon = require('@assets/icons/me/refresh.webp');
export const rechargeIcon = require('@assets/icons/wallet/recharge.webp');
export const walletIcon = require('@assets/icons/me/wallet.png');
export const withdrawIcon = require('@assets/icons/me/withdraw.png');
export const emptyHeaderImg = require('@components/assets/icons/header.webp');
export const whiteRightIcon = require('@components/assets/icons/right-icon.webp');
