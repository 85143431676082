import theme from '@style';
import LazyImage from '@basicComponents/image';
import React, {useMemo} from 'react';
import TouchableOpacity from '@basicComponents/touchable-opacity';
import {goCS} from '@utils';
import {BasicObject} from '@types';
import globalStore from '@/services/global.state';
import {useInnerStyle} from '../home.hooks';
import {View} from 'react-native';
const HomeService = ({img}: {img: string}) => {
  const {homeSideStyles} = useInnerStyle();
  const iconShadow = useMemo(() => {
    let shadow = {} as BasicObject;
    if (globalStore.isWeb) {
      shadow = {
        ...shadow,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
      };
    } else {
      shadow = {
        ...shadow,
        shadowColor: 'rgba(0, 0, 0, 0.25)',
        shadowOffset: {width: 0, height: 4},
        shadowOpacity: 1,
        shadowRadius: 4,
        elevation: 12,
      };
    }
    return shadow;
  }, []);

  return (
    <View
      style={[
        homeSideStyles.luckyspinShadow,
        homeSideStyles.vipNavsItemImg,
        homeSideStyles.mb16,
        theme.flex.center,
      ]}>
      <TouchableOpacity
        containerStyle={[
          theme.background.primary,
          homeSideStyles.service,
          iconShadow,
        ]}
        onPress={goCS}>
        <LazyImage
          width={homeSideStyles.serviceItemImg.width}
          height={homeSideStyles.serviceItemImg.height}
          radius={homeSideStyles.serviceItemImg.radius}
          imageUrl={img || require('@components/assets/icons/service.webp')}
        />
      </TouchableOpacity>
    </View>
  );
};

export default HomeService;
