import React, {FC, useState, useEffect} from 'react';
import {View, StyleSheet, ScrollView, Image} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {getSportsCard, getSportsStart} from '../home.service';
import theme from '@/style';
// import HomeSportStoreIcon from './svg/sport-store-icon';
import {formatDate} from '@/utils';
import {toSports} from '@utils/web';
import type {ICard} from '../home.type';
import globalStore from '@/services/global.state';

const DEFAULT_TOP_BG = '#CED2D6';
const DEFAULT_LOGO = require('@/assets/imgs/cricket.webp');
const IC_LIVE = require('@/assets/imgs/live.gif');

interface HomeSportProps {}
const HomeSport: FC<HomeSportProps> = () => {
  const {calcActualSize} = useScreenSize();
  const styles = StyleSheet.create({
    sportsBox: {
      marginBottom: calcActualSize(theme.paddingSize.l),
      paddingHorizontal: calcActualSize(theme.paddingSize.l),
    },
    scrollViewStyle: {
      width: calcActualSize(351),
    },
    card: {
      marginRight: calcActualSize(6),
    },
  });

  const [list, setList] = useState<ICard[]>([]);
  function request() {
    getSportsCard()
      .then(res => setList(res))
      .catch(e => {
        console.log('error: ', e);
      });
  }
  useEffect(() => {
    request();
  }, []);

  useEffect(() => {
    const pathname = location.pathname;
    const handlePopState = () => {
      if (location.pathname === pathname) {
        request();
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <View style={[styles.sportsBox]}>
      <ScrollView
        style={[styles.scrollViewStyle]}
        horizontal
        pagingEnabled
        snapToAlignment="start"
        showsHorizontalScrollIndicator={false}>
        {!!list.length &&
          list.map(v => (
            <SportCard {...v} onRefresh={l => setList(l)} key={v.fixtureId} />
          ))}
      </ScrollView>
    </View>
  );
};

interface SportCardProps extends ICard {}
const SportCard: FC<SportCardProps & {onRefresh: (list: ICard[]) => void}> = (
  props: SportCardProps & {onRefresh: (list: ICard[]) => void},
) => {
  const {
    playerA,
    playerB,
    league,
    status,
    beginTime,
    awayTeamLogo,
    homeTeamLogo,
  } = props;
  const isSelect = true;
  // const [store, setStroe] = useState<boolean>(false);
  const {calcActualSize} = useScreenSize();
  const styles = StyleSheet.create({
    card: {
      marginRight: calcActualSize(6),
    },
    top: {
      borderTopLeftRadius: calcActualSize(8),
      borderTopRightRadius: calcActualSize(8),
      paddingHorizontal: calcActualSize(8),
      paddingVertical: calcActualSize(2),
    },
    cap: {
      textTransform: 'capitalize',
    },
    upp: {
      textTransform: 'uppercase',
    },
    body: {
      borderWidth: calcActualSize(1),
      borderTopWidth: 0,
      borderBottomLeftRadius: calcActualSize(8),
      borderBottomRightRadius: calcActualSize(8),
      gap: calcActualSize(16),
    },
    logoWrap: {
      borderRadius: calcActualSize(4),
      width: calcActualSize(20),
      height: calcActualSize(20),
      backgroundColor: '#e7e7e7',
    },
    logo: {
      borderRadius: calcActualSize(4),
      width: '100%',
      height: '100%',
    },
    scores: {
      height: calcActualSize(52),
      marginRight: 10,
    },
    liveIcon: {
      padding: calcActualSize(4),
      width: calcActualSize(18),
      height: calcActualSize(18),
      borderRadius: calcActualSize(2),
      backgroundColor: '#b00',
    },
    endIcon: {
      width: calcActualSize(18),
      height: calcActualSize(18),
      borderRadius: calcActualSize(18),
      backgroundColor: theme.fontColor.accent,
    },
    line: {
      backgroundColor: theme.backgroundColor.grey,
      height: calcActualSize(1),
    },
    textRight: {
      textAlign: 'right',
    },
    gap2: {
      gap: calcActualSize(2),
    },
    gap4: {
      gap: calcActualSize(4),
    },
    gap6: {
      gap: calcActualSize(6),
    },
    gap8: {
      gap: calcActualSize(8),
    },
  });
  // const toggleStore = () => {
  //   // 调用store接口，操作成功组件内状态切换
  //   Promise.resolve()
  //     .then(() => setStroe(!store))
  //     .catch(e => console.log('e', e));
  // };
  const goDetail = async (item: SportCardProps) => {
    const list = await getSportsCard();
    if (!list) {
      return;
    }
    if (!list.some(i => i.fixtureId === item.fixtureId)) {
      globalStore.globalTotal.next({
        type: 'warning',
        message: 'The current event has ended',
      });
      if (props.onRefresh) {
        props.onRefresh(list);
      }
      return;
    }
    getSportsStart().then(r => {
      const [path, query] = r.split('?');
      toSports('Betrixx', `${path}/matchdetails/${item.fixtureId}?${query}`);
    });
  };
  return (
    <NativeTouchableOpacity
      style={[styles.card]}
      activeOpacity={1}
      onPress={() => goDetail(props)}>
      <View
        style={[
          theme.flex.flex,
          theme.flex.row,
          theme.flex.between,
          theme.flex.centerByCol,
          styles.top,
          {
            backgroundColor: isSelect
              ? theme.basicColor.primary
              : DEFAULT_TOP_BG,
          },
        ]}>
        <View
          style={[
            {
              minWidth: calcActualSize(180),
              marginRight: calcActualSize(12),
            },
          ]}>
          <Text
            calc
            fontSize={12}
            fontWeight="700"
            style={[styles.cap]}
            color={isSelect ? theme.basicColor.white : theme.fontColor.second}>
            {league}
          </Text>
        </View>
        {/* <NativeTouchableOpacity onPress={toggleStore}>
          <HomeSportStoreIcon
            fill={store ? '#FF9900' : theme.basicColor.white}
          />
        </NativeTouchableOpacity> */}
      </View>
      <View
        style={[
          theme.padding.xs,
          styles.body,
          theme.background.white,
          theme.flex.flex,
          theme.flex.row,
          theme.flex.centerByCol,
          theme.flex.between,
          {
            borderColor: isSelect
              ? theme.basicColor.primary
              : theme.fontColor.secAccent,
          },
        ]}>
        {/* 比赛的队伍 虚拟比赛添加 (V)*/}
        <View style={[theme.flex.flex, theme.flex.col, styles.gap6]}>
          {[
            {
              logo: homeTeamLogo
                ? `data:image/jpeg;base64,${homeTeamLogo}`
                : DEFAULT_LOGO,
              team: playerA.name,
            },
            {},
            {
              logo: awayTeamLogo
                ? `data:image/jpeg;base64,${awayTeamLogo}`
                : DEFAULT_LOGO,
              team: playerB.name,
            },
          ].map(({logo, team}, index) => (
            <View key={index}>
              {!team && <View style={[styles.line]} />}
              {!!team && (
                <View
                  style={[
                    theme.flex.flex,
                    theme.flex.row,
                    theme.flex.centerByCol,
                    styles.gap8,
                  ]}>
                  <View style={[styles.logoWrap]}>
                    <Image style={[styles.logo]} source={{uri: logo}} />
                  </View>
                  <Text
                    style={[styles.cap]}
                    calc
                    second
                    fontSize={12}
                    fontWeight="700">
                    {`${team}`}
                  </Text>
                </View>
              )}
            </View>
          ))}
        </View>
        <View style={[theme.flex.flex, theme.flex.row, theme.flex.centerByCol]}>
          {/* 未开始的比赛显示开赛时间 */}
          {status === '0' && (
            <View style={[theme.flex.flex, theme.flex.col, theme.flex.center]}>
              {formatDate(parseInt(beginTime) / 1000, 'hh:mm')
                .split(' ')
                .map((t: any) => (
                  <Text
                    key={t}
                    style={[styles.cap]}
                    calc
                    primary
                    fontSize={12}
                    fontWeight="700">
                    {t}
                  </Text>
                ))}
            </View>
          )}
          {/* 进行中的比赛和结束的比赛显示显示比分 */}
          {['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].includes(
            status,
          ) && (
            <View
              style={[
                theme.flex.flex,
                theme.flex.col,
                theme.flex.between,
                styles.scores,
              ]}>
              {
                // scores
                //   .reduce(
                //     (res: [string[], string[]], cur: string, index: number) => {
                //       if (isEnd && index % 2) {
                //         return res;
                //       }
                //       res[Math.floor(index / 2)].push(cur);
                //       return res;
                //     },
                //     [[], []] as [string[], string[]],
                //   )
                [playerA.score, playerB.score].map((teamScores, teamIndex) => {
                  // {\"batting\":\"\",\"wkts\":\"\",\"overs\":\"\",\"maxovers\":\"\",\"runs\":\"\"}
                  const score = JSON.parse(teamScores) as {
                    batting: string;
                    wkts: string;
                    overs: string;
                    maxovers: string;
                    runs: string;
                  };
                  return (
                    <View key={teamIndex}>
                      <Text
                        style={[
                          styles.cap,
                          styles.textRight,
                          {
                            color: false
                              ? theme.fontColor.accent
                              : theme.basicColor.primary,
                          },
                        ]}
                        calc
                        primary
                        fontSize={10}
                        fontWeight="700"
                        fontFamily="fontDin">
                        {(score.runs || '-') + '/' + (score.wkts || '-')}
                      </Text>
                      <Text
                        style={[
                          styles.cap,
                          styles.textRight,
                          {
                            color: true
                              ? theme.fontColor.accent
                              : theme.basicColor.primary,
                          },
                        ]}
                        calc
                        primary
                        fontSize={10}
                        fontWeight="700"
                        fontFamily="fontDin">
                        {(score.overs || '-') + '/' + (score.maxovers || '-')}
                      </Text>
                    </View>
                  );
                })
              }
            </View>
          )}
          {/* 进行中的比赛显示直播状态 */}
          {status == '1' && (
            <View
              style={[
                theme.flex.flex,
                theme.flex.col,
                theme.flex.centerByCol,
                styles.gap4,
              ]}>
              {/* <View
                style={[
                  theme.flex.flex,
                  theme.flex.row,
                  theme.flex.alignEnd,
                  styles.gap2,
                  styles.liveIcon,
                ]}>
                {[8, 5, 7].map((n, nIndex) => (
                  <View
                    key={nIndex}
                    style={[
                      {
                        height: calcActualSize(n),
                        width: calcActualSize(2),
                        backgroundColor: theme.basicColor.white,
                      },
                    ]}
                  />
                ))}
              </View> */}
              <Image style={styles.liveIcon} source={IC_LIVE} />
              <Text
                style={[styles.upp]}
                calc
                primary
                fontSize={10}
                fontWeight="700"
                fontFamily="fontDin">
                LIVE
              </Text>
            </View>
          )}
          {/* 结束的比赛显示结束图标 */}
          {status === '7' && (
            <View
              style={[
                theme.flex.flex,
                theme.flex.col,
                theme.flex.centerByCol,
                styles.gap4,
              ]}>
              <View
                style={[
                  theme.flex.flex,
                  theme.flex.row,
                  theme.flex.center,
                  styles.gap2,
                  styles.endIcon,
                ]}>
                {[9, 9].map((h, hIndex) => (
                  <View
                    key={hIndex}
                    style={[
                      {
                        height: calcActualSize(h),
                        width: calcActualSize(2),
                        backgroundColor: theme.basicColor.white,
                      },
                    ]}
                  />
                ))}
              </View>
              <Text
                style={[styles.upp]}
                calc
                accent
                fontSize={10}
                fontWeight="700"
                fontFamily="fontDin">
                STUMPS
              </Text>
            </View>
          )}
        </View>
      </View>
    </NativeTouchableOpacity>
  );
};

export default HomeSport;
