import {
  createVipBadge,
  getVipRender,
  maxVipLevel,
  useVipProgressList,
} from '@/components/business/vip';
import theme from '@style';
import {goTo, toPriceStr} from '@/utils';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import React, {useMemo} from 'react';
import {View} from 'react-native';
import Text from '@basicComponents/text';
import {useInnerStyle} from './me.hooks';
import Button from '@/components/basic/button';
import {useTranslation} from 'react-i18next';

export interface VipInfo {
  level: number;
  diff: number; // 差多少值结束
  nextValue: number; // 该等级结束值
}

export interface MeVipCardProps {
  login: boolean;
  vip: VipInfo;
}

const MeVipCard: React.FC<MeVipCardProps> = ({login, vip}) => {
  const {i18n} = useTranslation();
  const toVip = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('Vip');
  };
  const {
    size: {screenWidth},
    vipStyle,
  } = useInnerStyle();
  const currentVipOption = useMemo(() => getVipRender(vip.level), [vip.level]);
  const nextVipBadge = createVipBadge(Math.min(vip.level + 1, maxVipLevel));
  const topBlockWidth = screenWidth - 4 * theme.paddingSize.m;
  const [renderProgress] = useVipProgressList([
    {
      currentBadge: currentVipOption.badge,
      nextBadge: nextVipBadge,
      current: vip.nextValue - vip.diff,
      total: vip.nextValue,
      hasCurrentText: true,
    },
  ]);
  return (
    <NativeTouchableOpacity
      activeOpacity={0.8}
      onPress={toVip}
      style={[theme.position.abs, vipStyle.container]}>
      {currentVipOption.backgroundFn(
        <View style={[theme.margin.lrm]}>
          <View
            style={[
              theme.flex.row,
              theme.flex.alignEnd,
              {marginTop: -theme.paddingSize.l},
              theme.margin.leftm,
            ]}>
            <View style={[theme.margin.rightxs]}>
              {currentVipOption.sign('small')}
            </View>
            {/* 小进度条 */}
            <View
              style={[
                theme.margin.btmxxs,
                theme.flex.flex1,
                theme.flex.row,
                theme.flex.alignEnd,
              ]}>
              {renderProgress}
            </View>

            <View />
          </View>
          {vip.level >= maxVipLevel ? (
            <View />
          ) : (
            <View
              style={[
                theme.flex.row,
                theme.flex.between,
                theme.flex.centerByCol,
                theme.padding.lrm,
                theme.padding.tbxxs,
              ]}>
              <View style={[theme.flex.row]}>
                <Text
                  numberOfLines={2}
                  style={[theme.font.main, vipStyle.opacity]}
                  fontSize={theme.fontSize.xs}>
                  {i18n.t('me.vip.recharge')}{' '}
                  <Text blod>
                    {toPriceStr(vip.nextValue, {fixed: 0, thousands: true})}
                  </Text>{' '}
                  {i18n.t('me.vip.move')} VIP
                  {Math.min(vip.level + 1, maxVipLevel)}
                </Text>
              </View>
              <Button
                type="linear-primary"
                size="xsmall"
                titleBold
                title={'Deposit'}
                onPress={toVip}
              />
            </View>
          )}
        </View>,
        topBlockWidth,
      )}
    </NativeTouchableOpacity>
  );
};

export default MeVipCard;
