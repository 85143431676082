export default {
  v: '5.6.10',
  fr: 25,
  ip: 0,
  op: 25,
  w: 128,
  h: 128,
  nm: '旋转光',
  ddd: 0,
  assets: [
    {
      id: 'image_0',
      w: 128,
      h: 128,
      u: '/assets/lottie/',
      p: 'home_rebate.png',
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: '未标题-1 1.webp',
      cl: 'webp',
      refId: 'image_0',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              t: 0,
              s: [0],
            },
            {t: 24, s: [360]},
          ],
          ix: 10,
        },
        p: {a: 0, k: [64, 64, 0], ix: 2},
        a: {a: 0, k: [64, 64, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ip: 0,
      op: 25,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
