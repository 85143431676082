import {FadeInView} from '@/components/basic/animations';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {FlatList, ListRenderItemInfo, RefreshControl, View} from 'react-native';
import theme from '@style';
import Card from '@basicComponents/card';
import {useInnerStyle} from './casino.hooks';
import {
  NewGameListItem,
  postNewGameList,
} from '@/common-pages/casino/casino.service';
import {getCategories} from '@/common-pages/live-casino/live-casino-service';
import globalStore from '@/services/global.state';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {NoMoreData} from '@basicComponents/default-page';
import {goBack} from '@/utils';
import Wallet from '@/common-pages/wallet';
import {BasicObject, NavigatorScreenProps} from '@/types';
import {useFocusEffect} from '@react-navigation/native';
import NoData from '@/components/basic/error-pages/no-data';
import {toGame} from '@/common-pages/game-navigate';
import {useTranslation} from 'react-i18next';
import CasinoTabs from './casino-tabs';

const Casino = (props: NavigatorScreenProps) => {
  const [gameTypes, setGameTypes] = useState<{label: string; value: string}[]>(
    [],
  );

  const {i18n} = useTranslation();
  const {route} = props;
  const homeCategoryId = (route.params as BasicObject)?.categoryId;
  const homeGameType = (route.params as BasicObject)?.gameType;
  const [gameType, setGameType] = useState<string>(homeGameType || 'Lobby');
  const {
    size: {screenWidth, screenHeight},
  } = useInnerStyle();
  const [gameList, setGameList] = useState<NewGameListItem[]>([]);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const pageNo = useRef(1);
  const totalPage = useRef(1);

  const getGameCategories = () => {
    getCategories(5).then(res => {
      setGameTypes([
        {
          label: 'Lobby',
          value: 'Lobby',
        },
        ...res.map(v => ({
          label: v,
          value: v,
        })),
      ]);
    });
  };

  const handleFocusEffect = useCallback(() => {
    const sub = globalStore.tokenSubject.subscribe(token => {
      if (token) {
        globalStore.amountCheckOut.next({});
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, []);
  useFocusEffect(handleFocusEffect);

  const getList = useCallback(
    (showloading = true) => {
      showloading && globalStore.globalLoading.next(true);
      return postNewGameList({
        categoryId: homeCategoryId,
        pageNo: pageNo.current,
        gameType: ['Lobby', 'hotGames'].includes(gameType) ? '' : gameType,
        pageSize: 21,
      })
        .then(game => {
          if (pageNo.current <= 1) {
            setGameList(game.content || []);
          } else {
            setGameList([...gameList, ...(game.content || [])]);
          }

          totalPage.current = game.totalPages;
        })
        .finally(() => {
          globalStore.globalLoading.next(false);
          setRefreshing(false);
        });
    },
    [homeCategoryId, gameList, gameType],
  );

  const refreshNextPage = () => {
    if (totalPage.current === 1) {
      return;
    }
    pageNo.current++;
    if (pageNo.current > totalPage.current) {
      return;
    }
    globalStore.globalLoading.next(true);
    getList(true);
  };

  const computedSize = (num: number) => (num * screenWidth) / 375;
  const size = {
    computedS: computedSize(theme.paddingSize.s),
    cardWidth: computedSize(111.66),
    cardHeight: computedSize(155.66),
    computedL: computedSize(theme.paddingSize.l),
  };

  const renderItem = ({
    item: game,
    index,
  }: ListRenderItemInfo<NewGameListItem>) => {
    return (
      <View key={index}>
        <NativeTouchableOpacity onPress={() => toGame(game)}>
          <Card
            key={index}
            radius={theme.borderRadiusSize.m}
            backgroundColor={theme.basicColor.white}>
            <Card.Image
              imageUrl={game.otherUrl || game.gamePic}
              width={size.cardWidth}
              height={size.cardHeight}
            />
          </Card>
        </NativeTouchableOpacity>
      </View>
    );
  };

  useEffect(() => {
    getGameCategories();
  }, []);
  useEffect(() => {
    // setGameType(homeGameType);
    pageNo.current = 1;
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameType]);

  return (
    <FadeInView style={[{height: screenHeight}, theme.background.lightGrey]}>
      <DetailNavTitle
        title={i18n.t('home.title.casino')}
        hideAmount
        hideServer
        onBack={goBack}
        rightNode={<Wallet theme="dark" />}
      />
      <CasinoTabs
        tabOptions={gameTypes}
        value={gameType}
        onChange={setGameType}
      />

      <FlatList
        style={[theme.flex.flex1]}
        data={gameList}
        renderItem={renderItem}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              setRefreshing(true);
              getList(false);
            }}
          />
        }
        ListFooterComponentStyle={[theme.fill.fillW, theme.flex.center]}
        contentContainerStyle={[
          theme.flex.row,
          theme.flex.wrap,
          theme.flex.start,
          {
            gap: size.computedS,
            paddingHorizontal: size.computedL,
          },
        ]}
        onEndReached={refreshNextPage}
        ListEmptyComponent={
          <View
            style={[theme.padding.xxl, theme.flex.center, theme.fill.fillW]}>
            <NoData />
          </View>
        }
        ListFooterComponent={
          gameList &&
          gameList.length > 0 &&
          pageNo.current >= totalPage.current ? (
            <NoMoreData />
          ) : undefined
        }
      />
    </FadeInView>
  );
};

export default Casino;
