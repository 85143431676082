import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import globalStore from '@/services/global.state';
import theme from '@style';
import {useMemo} from 'react';
import {StatusBar, StyleSheet} from 'react-native';

function useSize() {
  const {screenWidth, screenHeight, designWidth} = useScreenSize();
  const size = useMemo(() => {
    const gameCardWidth =
      (screenWidth - 2 * theme.paddingSize.l - theme.paddingSize.s) / 2;
    const casinoCardWidth =
      (screenWidth - 2 * theme.paddingSize.l - 2 * theme.paddingSize.s) / 3;
    return {
      screenWidth,
      screenHeight,
      designWidth,
      topClipHeight: globalStore.isWeb ? 44 : StatusBar.currentHeight || 0,
      gameCardWidth,
      gameCardHeight: (gameCardWidth * 114) / 172,
      casinoCardWidth,
      casinoCardHeight: (casinoCardWidth * 156) / 112,
    };
  }, [screenWidth, screenHeight, designWidth]);
  return size;
}

export function useInnerStyle() {
  const size = useSize();
  const itemStyle = StyleSheet.create({
    smallCardContent: {
      height: 40,
    },
  });
  const listStyle = StyleSheet.create({
    list: {
      paddingBottom: 72,
    },
  });
  return {
    size,
    itemStyle,
    listStyle,
  };
}
