import {
  colorOrder,
  diceOrder,
  matkaOrder,
  pick3Order,
  quick3Order,
  lottoOrder,
  quickLottoOrder,
} from './order';

import {
  LotteryMode,
  isNumber,
} from '@/common-pages/mix-lottery/mix-lottery-service';

// prettier-ignore
const SUF_NUM = [
  'zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
  'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen',
];

function make3DigitOrder(code: string, num: string) {
  return 'ABC'
    .split('')
    .map(c => {
      if (code.includes(c)) {
        return num.charAt(code.indexOf(c));
      } else {
        return '-';
      }
    })
    .join('');
}

function makeSattaOrder(type: number) {
  // prettier-ignore
  const matkaType = [
    '',
    'Ank open', 'Ank close',
    'Jodi',
    'SP open', 'SP close',
    'DP open', 'DP close',
    'TP open', 'TP close',
    'Half Sangam open', 'Half Sangam close',
    'Full Sangam',
  ];
  return matkaType[type];
}

function makeComprehensiveOrder(item: any) {
  if (item.modeID === LotteryMode.FishPrawnCrab) {
    return ['Fish', 'Prawn', 'Crab'][item.betItem - 1];
  } else if (Number(item.modeID) === LotteryMode.TwoSide) {
    return item.betItem;
  } else {
    let length = 1;
    if (isNumber(item.modeID)) {
      length = item.modeName.split('')[0];
    }
    if (item.modeID == LotteryMode['1Digit']) {
      length = 1;
    }
    return item.betNo.slice(4 - length);
  }
}

function makeCarOrder(item: number) {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 'B', 'S', 'O', 'E'][item];
}
export type MagicBoxType =
  | 'dice'
  | 'color'
  | '3Digit'
  | 'quick3d'
  | 'matka'
  | 'quickRace'
  | 'scratch'
  | 'casino'
  | 'live'
  | 'sport'
  | 'kerala'
  | 'quickStateLottery'
  | 'stateLottery';
export type MagicBoxFrom = 'order' | 'pay';
export type MagicBoxResult = {
  number: string;
  amount: string; // 下注金额
  awardAmount?: string; // 赢钱金额, 仅当赢钱时返回
  status: 0 | 1 | 2; // 0,1,2 0待开奖 1已中奖 2未中奖
  playType?: string; // 游戏类型，（直接展示即可）仅当dice时返回
  digits?: string; // 数字 仅当matka时返回
  count?: number; // 总注数 当pick3 quick3d时返回
  tabName?: string; // 只有综合彩才会返回
};
export function transform(
  data: any,
  type: MagicBoxType,
  from?: MagicBoxFrom,
): MagicBoxResult[] | null {
  switch (type) {
    case 'dice':
      /**
       * number: "2","Big","Small", 'odd','even' // 骰子内容（转换成相应图片）
       * amount: 10 // 下注金额 （需要加上货币符号）
       * awardAmount: 10 // 赢钱金额，（直接展示即可，如果没有该值，则未开奖）
       * playType: "Sum" // 游戏类型，（直接展示即可）
       * status 0,1,2 0待开奖 1已中奖 2未中奖
       */
      if (from === 'order') {
        return data.typeList;
      } else {
        /**
         * {
            issueNo,
            selectTypeSet: ["sum_eight"],
            baseAmount: amount,
            totalAmount: amount,
        }
         */
        let [playType, number] = data.selectTypeSet[0].split('_');
        if (SUF_NUM.includes(number)) {
          number = SUF_NUM.indexOf(number);
        } else {
          // 将number的首字母变成大写
          number = number.charAt(0).toUpperCase() + number.slice(1);
        }
        return [
          {
            number: `${number}`,
            amount: data.baseAmount,
            playType,
            status: 0,
          },
        ];
      }
    case 'color':
      if (from === 'order') {
        /**
         * number: "1","v",'r','g'
         * amount: 10
         * awardAmount: 10
         * status:
         */
        return data.subsetList.map((i: any) => ({
          ...i,
          number: i.selectValue,
          status: data.openStatus === 1 ? 0 : i.awardAmount > 0 ? 1 : 2,
        }));
      } else {
        return [
          {
            ...data,
            number: data.selectValue,
            amount: data.amount,
            status: 0,
          },
        ];
      }
    case '3Digit':
    case 'quick3d':
      if (from === 'order') {
        /**
         * number:'-12','1-2'
         * amount: 10
         * awardAmount: 10
         * status: 0,1,2
         * count: 2
         */
        return data.codeLists.map((i: any) => {
          const [code, num] = i.indexCode.split('=');
          return {
            ...i,
            number: make3DigitOrder(code, num),
            amount: i.pickAmount,
            count: i.pickCount,
            awardAmount: i.winAmount,
            status: i.wonCode.startsWith('*')
              ? 0
              : i.winAmount > 0 || i.codeWinAmount > 0
              ? 1
              : 2,
          };
        });
      } else {
        let tickets = data.tickets || [
          ...data.levelOne,
          ...data.levelTwo,
          ...data.levelThr,
        ];
        tickets.forEach((i: any) => {
          i.pickAmount = data.pickInfos?.filter(
            (i: any) => i.pickInfoId == i.pickInfoId,
          )[0].pickAmount;
        });
        return tickets.map((i: any) => {
          return {
            number: make3DigitOrder(i.pickIndex, i.pickNumber),
            amount: i.pickAmount,
            count: i.pickCount,
            status: 0,
          };
        });
      }
    case 'matka':
      /**
       * digits: '2'
       */
      if (from === 'order') {
        return data.userDigits.map((i: any) => {
          return {
            ...i,
            number: makeSattaOrder(i.gameType),
            amount: i.points,
            status: data.openStatus === 1 ? 0 : i.digitsWonAmount > 0 ? 1 : 2,
            awardAmount: i.digitsWonAmount,
          };
        });
      } else {
        return data.matkaBaseReqList.map((i: any) => {
          return {
            ...i,
            status: 0,
            number: makeSattaOrder(i.gameType),
            amount: i.amount,
          };
        });
      }
    case 'quickRace':
      if (from === 'order') {
        return null;
      } else {
        return [
          {
            number: `${makeCarOrder(data.betItem)}`,
            amount: data.chips,
            status: 0,
          },
        ];
      }
    case 'quickStateLottery':
    case 'stateLottery':
      const tickets =
        data.ticketsLists ||
        data.tickets.map((i: any) => {
          const item = {
            ...i,
            tabName: ['1st-prize', '2nd-prize'][i.tabID - 1],
            modeName: LotteryMode[i.modeID],
          };
          if (isNumber(item.modeID)) {
            const digits = `${item.betNo}`;
            item.betNo = `${Array(4 - digits.length)
              .fill('0')
              .join('')}${digits}`;
          }
          return item;
        });

      return tickets.map((i: any) => {
        const mode = i.modeName.replace(/\//g, '');
        return {
          ...i,
          number: makeComprehensiveOrder(i),
          modeName: mode,
          type: mode,
          tabName: i.tabName,
          amount: i.amount + (i.fee || 0),
          awardAmount: i.prize,
          status: data.status || 0,
          isNumber: [2, 3, 4, 5, 6, 7, 8].includes(i.modeId),
          isD: [1, 2, 3, 4].includes(i.modeID),
        };
      });

    case 'scratch':
    case 'casino':
    case 'live':
    case 'sport':
    case 'kerala':
      return null;
  }
}

// 随机生成1-14（包含）之间的整数
// function randomNumber(min: number, max: number) {
//   return Math.floor(Math.random() * (max - min + 1)) + min;
// }

export function startOrder(data: string) {
  try {
    const res = JSON.parse(data);
    const {gameType, rawData} = res;
    order(rawData, gameType);
  } catch (error) {
    console.log(error);
  }
}

export function order(data: any, type: MagicBoxType): Promise<any> {
  const request: Record<MagicBoxType, any> = {} as Record<MagicBoxType, any>;
  request.color = colorOrder;
  request.dice = diceOrder;
  request['3Digit'] = pick3Order;
  request.quick3d = quick3Order;
  request.matka = matkaOrder;
  request.stateLottery = lottoOrder;
  request.quickStateLottery = quickLottoOrder;

  if (request[type]) {
    return request[type](data);
  } else {
    return Promise.reject(
      new Error(`this game type is not supported: ${type}`),
    );
  }
}
