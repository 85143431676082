import React from 'react';
import {BannerListItem} from '../home.type';
import theme from '@/style';
import Carousel from 'react-native-reanimated-carousel';
import {View, StyleSheet} from 'react-native';
import LazyImage from '@basicComponents/image';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {getBannerList} from '../home.service';
import {useInnerStyle} from '../home.hooks';
// import globalStore from '@/services/global.state';
import {goToUrl} from '@/common-pages/game-navigate';
// import Swiper from '@/components/basic/swiper';

export interface HomeBannerProps {
  version?: number;
}

const HomeBanner: React.FC<HomeBannerProps> = ({version}) => {
  const [bannerList, setbannerList] = React.useState<BannerListItem[]>([]);
  const {
    size: {screenWidth},
  } = useInnerStyle();
  const bannerWidth = screenWidth - theme.paddingSize.l * 2;
  const bannerHeight = (bannerWidth * 108) / 351;

  const [currentIndex, setCurrentIndex] = React.useState(0);
  const styles = StyleSheet.create({
    idotBox: {
      bottom: theme.paddingSize.xxl,
      width: bannerWidth,
    },
    idot: {
      width: theme.paddingSize.xxs,
      height: theme.paddingSize.xxs,
      borderRadius: theme.paddingSize.xxs / 2,
      marginHorizontal: theme.paddingSize.xxs / 2,
      backgroundColor: theme.backgroundColor.palegrey,
    },
    idotActive: {
      backgroundColor: theme.basicColor.primary,
      width: theme.paddingSize.l,
    },
  });

  React.useEffect(() => {
    getBannerList(2).then(banner => {
      setbannerList(banner);
    });
  }, [version]);
  return (
    <View style={[theme.position.rel, theme.padding.lrl]}>
      {bannerList?.length > 0 && (
        <Carousel
          loop
          style={[theme.borderRadius.m, theme.overflow.hidden]}
          width={bannerWidth}
          height={bannerHeight}
          autoPlay
          autoPlayInterval={3000}
          scrollAnimationDuration={1000}
          data={bannerList}
          onProgressChange={e => {
            let index = Math.abs(Math.round(e / bannerWidth));
            if (index >= bannerList?.length) {
              index = 0;
            }
            if (index !== currentIndex) {
              setCurrentIndex(index);
            }
          }}
          renderItem={({item}) => (
            <NativeTouchableOpacity
              style={[theme.fill.fill]}
              onPress={() => {
                goToUrl(item.skipLinks, item.title);
              }}>
              <View
                style={[
                  theme.fill.fill,
                  theme.borderRadius.m,
                  theme.overflow.hidden,
                  theme.background.primary,
                ]}>
                <LazyImage
                  occupancy={theme.backgroundColor.palegrey}
                  imageUrl={item.bannerImg}
                  height={bannerHeight}
                  width={bannerWidth}
                />
              </View>
            </NativeTouchableOpacity>
          )}
        />
      )}
      {!bannerList?.length && (
        <View
          style={[
            {
              height: bannerHeight,
              width: bannerWidth,
            },
            theme.borderRadius.m,
            theme.background.palegrey,
          ]}
        />
      )}
      {bannerList?.length > 0 && (
        <View
          style={[
            theme.position.abs,
            // theme.fill.fillW,
            theme.flex.row,
            theme.flex.center,
            styles.idotBox,
          ]}>
          {bannerList.map((_v, i) => (
            <View
              key={i}
              style={[styles.idot, currentIndex === i && styles.idotActive]}
            />
          ))}
        </View>
      )}
    </View>
  );

  // return globalStore.isWeb ? (
  //   <Swiper
  //     pictureWidth={bannerWidth}
  //     seamless={true}
  //     paddingRight={theme.paddingSize.l}
  //     height={bannerHeight}
  //     autoPlay={true}
  //     hasIndicator={true}
  //     pictures={bannerList?.map((item: {bannerImg: string}) => ({
  //       uri: item.bannerImg,
  //     }))}
  //     onItemPress={_index => {
  //       goToUrl(bannerList[_index].skipLinks, bannerList[_index].title);
  //     }}
  //   />
  // ) : (
  //   <View style={[theme.position.rel, theme.padding.lrl]}>
  //     {bannerList?.length > 0 && (
  //       <Carousel
  //         loop
  //         style={[theme.borderRadius.m, theme.overflow.hidden]}
  //         width={bannerWidth}
  //         height={bannerHeight}
  //         autoPlay
  //         autoPlayInterval={3000}
  //         scrollAnimationDuration={1000}
  //         data={bannerList}
  //         onProgressChange={e => {
  //           let index = Math.abs(Math.round(e / bannerWidth));
  //           if (index >= bannerList?.length) {
  //             index = 0;
  //           }
  //           if (index !== currentIndex) {
  //             setCurrentIndex(index);
  //           }
  //         }}
  //         renderItem={({item}) => (
  //           <NativeTouchableOpacity
  //             style={[theme.fill.fill]}
  //             onPress={() => {
  //               goToUrl(item.skipLinks, item.title);
  //             }}>
  //             <View
  //               style={[
  //                 theme.fill.fill,
  //                 theme.borderRadius.m,
  //                 theme.overflow.hidden,
  //                 theme.background.primary,
  //               ]}>
  //               <LazyImage
  //                 occupancy={theme.backgroundColor.palegrey}
  //                 imageUrl={item.bannerImg}
  //                 height={bannerHeight}
  //                 width={bannerWidth}
  //               />
  //             </View>
  //           </NativeTouchableOpacity>
  //         )}
  //       />
  //     )}
  //     {!bannerList?.length && (
  //       <View
  //         style={[
  //           {
  //             height: bannerHeight,
  //             width: bannerWidth,
  //           },
  //           theme.borderRadius.m,
  //           theme.background.palegrey,
  //         ]}
  //       />
  //     )}
  //     {bannerList?.length > 0 && (
  //       <View
  //         style={[
  //           theme.position.abs,
  //           theme.fill.fillW,
  //           theme.flex.row,
  //           theme.flex.center,
  //           styles.idotBox,
  //         ]}>
  //         {bannerList.map((_v, i) => (
  //           <View
  //             key={i}
  //             style={[styles.idot, currentIndex === i && styles.idotActive]}
  //           />
  //         ))}
  //       </View>
  //     )}
  //   </View>
  // );
};

export default HomeBanner;
