import React from 'react';

export default ({active}: {active: boolean}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <g filter="url(#filter0_d_10421_37722)">
      <path
        d="M10.667 14.6668L13.3337 12.0002L10.667 9.3335"
        stroke={active ? 'rgba(148, 157, 183, 1)' : 'rgba(148, 157, 183, 0.50)'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_10421_37722"
        x="0"
        y="0"
        width="24"
        height="25"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.581264 0 0 0 0 0.615618 0 0 0 0 0.718678 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_10421_37722"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_10421_37722"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
