import React, {useState, useEffect} from 'react';
import {HomeGameCardItem} from '../home.type';
import theme from '@/style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {goTo, getUrlParams} from '@/utils';
import Text from '@/components/basic/text';
import {View, Image, ImageBackground, StyleSheet} from 'react-native';
import LazyImage from '@/components/basic/image';
import {toGame} from '@/common-pages/game-navigate';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

interface HomeFloorGamesProps {
  icon: string;
  title: string;
  list: HomeGameCardItem[];
}
const HomeFloorGames: React.FC<HomeFloorGamesProps> = ({list, icon, title}) => {
  const {calcActualSize, screenWidth} = useScreenSize();
  const styles = StyleSheet.create({
    titleBox: {
      height: calcActualSize(40),
    },
    smallCard: {
      height: calcActualSize(156),
      // 25%加上边距后,一行不会超过3个,但也不至于少于2个
      // 所以flex1+minwidth25%可以视为一行三个
      minWidth: '25%',
      flex: 1,
    },
    bigCard: {
      height: calcActualSize(156),
      width: '100%',
    },
  });
  const [list4Item, setList4Item] = useState<HomeGameCardItem[]>([]);
  useEffect(() => {
    if (list.length === 4) {
      setList4Item(list);
    } else {
      // name: Dice Game 1, WinGo 1, Racecar 1, State Lottery 3
      const filterNames = ['Dice Game', 'WinGo', 'Racecar', 'State Lottery'];
      setList4Item(list.filter(v => filterNames.includes(v.name)));
    }
  }, [list]);

  const goGames = () => {
    goTo('Lottery');
  };
  const jumpGame = (v: HomeGameCardItem) => {
    if (
      v.name.toLowerCase() === 'dice game' ||
      v.gameType.toLowerCase() === 'dice'
    ) {
      const params = getUrlParams(v.gameUrl);
      params.cycle = 1;
      goTo('Dice', params);
    } else if (
      v.name.toLowerCase() === 'wingo' ||
      v.gameType.toLowerCase() === 'color'
    ) {
      goTo('Color', {...getUrlParams(v.otherParams), name: v.name});
    } else if (v.name.toLowerCase() === 'state lottery') {
      goTo('Lottery');
    } else {
      toGame(v);
    }
  };

  const renderItem = () => {
    return (
      <View
        style={[
          theme.flex.wrap,
          theme.flex.row,
          {
            width: screenWidth,
            gap: calcActualSize(theme.paddingSize.s),
            paddingHorizontal: calcActualSize(theme.paddingSize.l),
            paddingBottom: calcActualSize(theme.paddingSize.l),
          },
        ]}>
        {list4Item?.map((v, i) => {
          return (
            <NativeTouchableOpacity
              onPress={() => jumpGame(v)}
              key={i}
              style={[
                theme.background.palegrey,
                theme.borderRadius.m,
                theme.overflow.hidden,
                styles[`${i === 3 ? 'big' : 'small'}Card`],
              ]}>
              <LazyImage
                imageUrl={v.otherUrl! || v.gamePic}
                width={'100%'}
                height={'100%'}
              />
            </NativeTouchableOpacity>
          );
        })}
      </View>
    );
  };
  return (
    <View>
      <ImageBackground
        style={[
          styles.titleBox,
          theme.flex.row,
          theme.flex.centerByCol,
          {
            paddingHorizontal: calcActualSize(theme.paddingSize.l),
          },
        ]}
        source={require('@assets/imgs/home/home-floor-title-most-popular-bg.webp')}>
        <NativeTouchableOpacity
          onPress={goGames}
          style={[theme.flex.row, theme.flex.centerByCol, theme.fill.fillW]}>
          {icon ? (
            <Image
              source={{uri: icon}}
              style={[
                {
                  width: calcActualSize(theme.iconSize.xxl),
                  height: calcActualSize(theme.iconSize.xxl),
                  marginRight: calcActualSize(theme.paddingSize.s),
                },
              ]}
            />
          ) : (
            <View
              style={[
                theme.background.palegrey,
                {
                  width: calcActualSize(theme.iconSize.xxl),
                  height: calcActualSize(theme.iconSize.xxl),
                  marginRight: calcActualSize(theme.paddingSize.s),
                },
              ]}
            />
          )}
          <Text calc size="medium" main blod>
            {title}
          </Text>
        </NativeTouchableOpacity>
      </ImageBackground>
      {renderItem()}
    </View>
  );
};

export default HomeFloorGames;
