import theme from '@style';
import React from 'react';
import {View} from 'react-native';
import Text from '@basicComponents/text';
import globalStore from '@/services/global.state';
import LazyImage from '@basicComponents/image';
import {rechargeIcon, refreshIcon, withdrawIcon} from './me.variable';
import {useInnerStyle} from './me.hooks';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {IUserInfo} from '@/services/global.service';
import {goTo, toPriceStr} from '@/utils';
import {useTranslation} from 'react-i18next';

export interface MeAmountProps {
  login: boolean;
  user?: IUserInfo;
  onRefresh?: () => void;
}

const MeAmount: React.FC<MeAmountProps> = ({login, user, onRefresh}) => {
  const {i18n} = useTranslation();
  const {
    amountStyle,
    size: {walletIconSize},
  } = useInnerStyle();
  const toRecharge = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    // 跳转recharge
    goTo('Recharge');
  };

  const toWithdraw = () => {
    if (!login) {
      goTo('Login');
      return;
    }
    goTo('Withdraw');
  };
  return (
    <View
      style={[
        theme.padding.tbxxl,
        theme.padding.lrl,
        theme.borderRadius.m,
        theme.background.white,
        theme.margin.btml,
      ]}>
      <View
        style={[
          theme.padding.lrl,
          amountStyle.totalBalance,
          theme.margin.btmm,
        ]}>
        <Text fontSize={theme.fontSize.s}>
          {i18n.t('me.money.totalBalance')}
        </Text>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <NativeTouchableOpacity
            onPress={toRecharge}
            style={[theme.flex.row, theme.flex.alignEnd]}>
            {login ? (
              <>
                <Text
                  fontFamily="fontDin"
                  blod
                  fontSize={theme.fontSize.m}
                  style={theme.margin.rightxxs}>
                  {globalStore.currency}
                </Text>
                <Text
                  fontFamily="fontDin"
                  fontSize={20}
                  blod
                  style={[theme.margin.rightl]}>
                  {user
                    ? toPriceStr(user.userBalance, {
                        thousands: true,
                        showCurrency: false,
                      })
                    : '0'}
                </Text>
              </>
            ) : (
              <Text
                fontFamily="fontDin"
                fontSize={20}
                blod
                style={[theme.margin.rightl]}>
                {'******'}
              </Text>
            )}
          </NativeTouchableOpacity>
          <NativeTouchableOpacity onPress={onRefresh}>
            <LazyImage
              occupancy="#0000"
              width={theme.iconSize.s}
              height={theme.iconSize.s}
              imageUrl={refreshIcon}
            />
          </NativeTouchableOpacity>
        </View>
      </View>
      <View
        style={[
          theme.padding.lrl,
          theme.flex.row,
          // theme.flex.between,
          theme.flex.around,
          theme.flex.centerByCol,
          theme.margin.topm,
        ]}>
        <NativeTouchableOpacity onPress={toRecharge}>
          <View style={[theme.flex.col, theme.flex.center, amountStyle.item]}>
            <LazyImage
              occupancy="#0000"
              imageUrl={rechargeIcon}
              width={walletIconSize}
              height={walletIconSize}
            />
            <Text style={[theme.font.second, theme.padding.topxxs]}>
              {i18n.t('me.money.recharge')}
            </Text>
          </View>
        </NativeTouchableOpacity>
        <NativeTouchableOpacity onPress={toWithdraw}>
          <View style={[theme.flex.col, theme.flex.center, amountStyle.item]}>
            <LazyImage
              occupancy="#0000"
              imageUrl={withdrawIcon}
              width={walletIconSize}
              height={walletIconSize}
            />
            <Text style={[theme.font.second, theme.padding.topxxs]}>
              {i18n.t('me.money.withdraw')}
            </Text>
          </View>
        </NativeTouchableOpacity>
      </View>
    </View>
  );
};

export default MeAmount;
