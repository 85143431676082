import DetailNavTitle from '@/components/business/detail-nav-title';
import React, {useCallback, useRef} from 'react';
import {useEffect} from 'react';
import {getBetBy} from '../home/home.service';
import {goBack, goTo, navigationRef} from '@/utils';
import Spin from '@/components/basic/spin';
import theme from '@/style';
import {CommonActions} from '@react-navigation/native';

const BetByContainer = () => {
  const bt = useRef<any>();

  useEffect(() => {
    const status = localStorage.getItem('betbyRouteStatus');
    if (status) {
      setTimeout(() => {
        const _status = JSON.parse(status);
        if (_status.routes.find((r: any) => r.name === 'BetBy')) {
          navigationRef.dispatch(CommonActions.reset(_status));
        } else {
          localStorage.removeItem('betbyRouteStatus');
        }
      }, 100);
    } else {
      setTimeout(() => {
        if (navigationRef.getState()) {
          localStorage.setItem(
            'betbyRouteStatus',
            JSON.stringify(navigationRef.getState()),
          );
        }
      }, 100);
    }
  }, []);

  useEffect(() => {
    getBetBy().then(res => {
      const {brandId, token} = res;
      bt.current = new BTRenderer().initialize({
        brand_id: brandId,
        token: token,
        onTokenExpired() {
          goTo('Login');
        },
        themeName: 'default',
        lang: 'en',
        target: document.getElementById('betby'),
        betSlipOffsetTop: 0,
        betslipZIndex: 999,
        onSessionRefresh() {
          goTo('Login');
        },
        onLogin() {
          goTo('Login');
        },
        onRegister() {
          goTo('Login');
        },
      });
    });
    return () => {
      if (bt.current) {
        bt.current.kill();
        bt.current = undefined;
      }
    };
  }, []);
  const handleBack = useCallback(() => {
    localStorage.removeItem('betbyRouteStatus');
    goBack();
  }, []);
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}>
      <DetailNavTitle onBack={handleBack} hideServer title="BetBy Sports" />
      <div id="betby" style={{flex: 1, overflow: 'auto'}}>
        <Spin loading style={theme.fill.fill} />
      </div>
    </div>
  );
};

export default BetByContainer;
