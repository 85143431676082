import {useResponsiveDimensions} from '@/utils';
import React from 'react';

const HomeRabateAnimation = () => {
  const LottieView = require('lottie-react-native').default;
  const {width} = useResponsiveDimensions();
  const lottieStyle = {
    height: (64 * width) / 375,
    width: (64 * width) / 375,
  };
  return (
    <LottieView
      style={[lottieStyle]}
      source={require('./home-rebate-animation-config.json')}
      loop
      autoPlay
      imageAssetsFolder={'lottie'}
    />
  );
};

export default HomeRabateAnimation;
