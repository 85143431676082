import React from 'react';
import CommonLiveCasino from '@/common-pages/live-casino';
import {NavigatorScreenProps} from '@/types';
import Wallet from '@/common-pages/wallet';

const LiveCasino = (props: NavigatorScreenProps) => {
  return <CommonLiveCasino {...props} renderAmount={<Wallet theme="dark" />} />;
};

export default LiveCasino;
