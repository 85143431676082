import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import theme from '@style';
import {useMemo} from 'react';
import {StyleSheet} from 'react-native';

function useSize() {
  const {screenWidth, screenHeight, designWidth} = useScreenSize();
  const boxContainerWidth = screenWidth - theme.paddingSize.l * 2;
  const lotteryCardWidth = (boxContainerWidth - theme.paddingSize.s * 2) / 3;
  const lotteryCardWidth2x =
    boxContainerWidth - lotteryCardWidth - theme.paddingSize.s - 1;
  const lotteryCardWidth3x = boxContainerWidth;
  const lotteryCardHeight = (lotteryCardWidth * 140) / 113;
  const hotGameCardHeight = (lotteryCardWidth * 156) / 112;
  const otherCardWidth = (boxContainerWidth - theme.paddingSize.s) / 2;
  const otherCardWidth2x = boxContainerWidth;
  const otherCardHeight = (otherCardWidth * 114) / 172;
  const todayHeaderWidth = boxContainerWidth;
  const todayHeaderHeight = (todayHeaderWidth * 52) / 351;
  const carImgBox = (screenWidth / 375) * 80;

  const size = useMemo(() => {
    return {
      screenWidth,
      screenHeight,
      designWidth,
      boxContainerWidth,
      lottery: {
        cardWidth: lotteryCardWidth,
        cardWidth2x: lotteryCardWidth2x,
        cardWidth3x: lotteryCardWidth3x,
        cardHeight: lotteryCardHeight,
        hotGameCardHeight,
      },
      other: {
        cardWidth: otherCardWidth,
        cardWidth2x: otherCardWidth2x,
        cardHeight: otherCardHeight,
        carHeight: lotteryCardHeight,
        carWidth: lotteryCardWidth,
        carImgBox,
      },
      today: {
        headerWidth: todayHeaderWidth,
        headerHeight: todayHeaderHeight,
      },
    };
  }, [
    screenWidth,
    screenHeight,
    designWidth,
    boxContainerWidth,
    lotteryCardWidth,
    lotteryCardWidth2x,
    lotteryCardWidth3x,
    lotteryCardHeight,
    otherCardWidth,
    otherCardWidth2x,
    otherCardHeight,
    todayHeaderWidth,
    todayHeaderHeight,
    carImgBox,
    hotGameCardHeight,
  ]);
  return size;
}

export function useInnerStyle() {
  const size = useSize();
  const goldStyles = StyleSheet.create({
    image: {
      width: (72 * size.screenWidth) / 375,
      height: (72 * size.screenWidth) / 375,
    },
    title: {
      lineHeight: 14,
      height: 14,
      textTransform: 'capitalize',
    },
  });

  const winningInfoStyle = StyleSheet.create({
    container: {
      borderBottomLeftRadius: theme.borderRadiusSize.m,
      borderBottomRightRadius: theme.borderRadiusSize.m,
    },
  });

  const todayDefaultHeaderSize =
    (size.today.headerWidth * theme.imageSize.s) / 351;
  const todayHeaderTabSize =
    (size.today.headerWidth * theme.iconSize.xxl) / 351;

  const todayStyles = StyleSheet.create({
    noTag: {
      width: (size.today.headerWidth * 67) / 351,
      height: (size.today.headerWidth * 16) / 351,
    },
    topHeaderWrap: {
      borderRadius: todayDefaultHeaderSize / 2 + 2,
      height: todayDefaultHeaderSize + 4,
      width: todayDefaultHeaderSize + 4,
      borderWidth: 2,
      borderColor: '#CAD6F9',
    },
    topHeader: {
      height: todayDefaultHeaderSize,
      width: todayDefaultHeaderSize,
    },
    topHeaderTag: {
      height: todayHeaderTabSize,
      width: todayHeaderTabSize,
      right: -(size.today.headerWidth * 10) / 351,
      top: -(size.today.headerWidth * 14) / 351,
    },
    headerImg: {
      width: size.today.headerWidth,
      height: size.today.headerHeight,
    },
    itemAmountWrap: {
      height: 18,
      minWidth: 100,
    },
    topText: {
      width: 20,
    },
  });

  const userInfoStyles = StyleSheet.create({
    item: {
      width: 98,
    },
  });

  const homeSideStyles = StyleSheet.create({
    serviceItemImg: {
      width: (46 * size.screenWidth) / 375,
      height: (46 * size.screenWidth) / 375,
      radius: (46 * size.screenWidth) / 375,
    },
    luckyspinItemImg: {
      width: (60 * size.screenWidth) / 375,
      height: (60 * size.screenWidth) / 375,
      radius: (46 * size.screenWidth) / 375,
    },
    luckyspinShadow: {
      height: (64 * size.screenWidth) / 375,
      backgroundColor: 'rgba(0,0,0,0.4)',
      borderRadius: (64 * size.screenWidth) / 375,
    },

    vipNavsItemImg: {
      width: (64 * size.screenWidth) / 375,
      height: (64 * size.screenWidth) / 375,
    },
    vipNavsItemTag: {
      position: 'absolute',
      top: 5,
      right: 5,
    },
    mb16: {
      marginBottom: theme.paddingSize.xxl,
    },
    wrap: {
      right: theme.paddingSize.l,
      bottom: 38,
      zIndex: 5,
      float: 'right',
    },
    service: {
      width: (52 * size.screenWidth) / 375,
      borderRadius: (52 * size.screenWidth) / 375,
      padding: 3,
    },
    chat: {
      width: (46 * size.screenWidth) / 375,
      borderRadius: (46 * size.screenWidth) / 375,
    },
  });

  const spaceAreaStyles = StyleSheet.create({
    bottom: {
      height: 72,
    },
  });
  return {
    size,
    goldStyles,
    winningInfoStyle,
    todayStyles,
    userInfoStyles,
    homeSideStyles,
    spaceAreaStyles,
  };
}
